* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html,
.page {
  height: 100%;
  width: 100%;
}


html,
body {
  width: 100%;
  height: calc(100 * var(--vh, 1vh));
  /* height: 80vh; */
  /* height: calc(var(--initvh)); */
  /* height: -webkit-fill-available;.my-element {
  height: 100svh;
  height: 100vh; */
  /* max-height: calc(100vh * 80%); */
  overflow: hidden;
  /* Hide horizontal scrollbar */
  /* scroll-behavior: smooth; */
}

#container {
  display: flex;
  /* width: 200vw; */
  /* 100vw for each section */
}

section {
  flex-shrink: 0;
  /* Prevents the sections from shrinking */
  /* width: 100vw; */
  height: 100%;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --primary: #fdd835;
    --primary-hover: #ffeb3b;
    --primary-focus: rgba(253, 216, 53, 0.25);
    --primary-inverse: rgba(0, 0, 0, 0.75);
  }
}

@font-face {
  font-family: 'Lacquer';
  src: url('../public/Lacquer-Regular.ttf') format('truetype');
  font-weight: 400;
  /* regular weight */
}