* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html, .page {
  width: 100%;
  height: 100%;
}

html, body {
  height: calc(100 * var(--vh, 1vh));
  width: 100%;
  overflow: hidden;
}

#container {
  display: flex;
}

section {
  flex-shrink: 0;
  height: 100%;
}

.page {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --primary: #fdd835;
    --primary-hover: #ffeb3b;
    --primary-focus: #fdd83540;
    --primary-inverse: #000000bf;
  }
}

@font-face {
  font-family: Lacquer;
  src: url("Lacquer-Regular.5af82249.ttf") format("truetype");
  font-weight: 400;
}

/*# sourceMappingURL=index.0e817986.css.map */
